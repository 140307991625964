import * as _isAndroid2 from "@braintree/browser-detection/is-android";
var _isAndroid = "default" in _isAndroid2 ? _isAndroid2.default : _isAndroid2;
import * as _isChromeOs2 from "@braintree/browser-detection/is-chrome-os";
var _isChromeOs = "default" in _isChromeOs2 ? _isChromeOs2.default : _isChromeOs2;
import * as _isIos2 from "@braintree/browser-detection/is-ios";
var _isIos = "default" in _isIos2 ? _isIos2.default : _isIos2;
import * as _isChrome2 from "@braintree/browser-detection/is-chrome";
var _isChrome = "default" in _isChrome2 ? _isChrome2.default : _isChrome2;
import * as _isIe2 from "@braintree/browser-detection/is-ie";
var _isIe = "default" in _isIe2 ? _isIe2.default : _isIe2;
import * as _isEdge2 from "@braintree/browser-detection/is-edge";
var _isEdge = "default" in _isEdge2 ? _isEdge2.default : _isEdge2;
import * as _isIe4 from "@braintree/browser-detection/is-ie9";
var _isIe3 = "default" in _isIe4 ? _isIe4.default : _isIe4;
import * as _isIe6 from "@braintree/browser-detection/is-ie10";
var _isIe5 = "default" in _isIe6 ? _isIe6.default : _isIe6;
import * as _isFirefox2 from "@braintree/browser-detection/is-firefox";
var _isFirefox = "default" in _isFirefox2 ? _isFirefox2.default : _isFirefox2;
import * as _isIosWebview2 from "@braintree/browser-detection/is-ios-webview";
var _isIosWebview = "default" in _isIosWebview2 ? _isIosWebview2.default : _isIosWebview2;
var exports = {};
var isAndroid = _isAndroid;
var isChromeOS = _isChromeOs;
var isIos = _isIos;
var isChrome = _isChrome;
function hasSoftwareKeyboard() {
  return isAndroid() || isChromeOS() || isIos();
}
function isChromeIos() {
  return isChrome() && isIos();
}
exports = {
  isIE: _isIe,
  isEdge: _isEdge,
  isIe9: _isIe3,
  isIe10: _isIe5,
  isAndroid: isAndroid,
  isChromeOS: isChromeOS,
  isChromeIos: isChromeIos,
  isFirefox: _isFirefox,
  isIos: isIos,
  isIosWebview: _isIosWebview,
  hasSoftwareKeyboard: hasSoftwareKeyboard
};
export default exports;